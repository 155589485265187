let getRangeDisplay = (range) => {
    if (range.length == 0) return ""
    if (range.length == 1) return `Table ${range[0].locationName}`

    let first = range[0].locationName
    let last = range[range.length - 1].locationName
    return `Tables ${first}-${last}`
}

export const createSectionLocationRanges = (section) => {
    let originalLocations = section.locations.map(el => { return { ...el, parsed: parseInt(el.locationName) }})
    let nonInteger = originalLocations.filter(el => { return _.isNaN(el.parsed) })
    let integer = originalLocations.filter(el => { return !_.isNaN(el.parsed) })

    var ranges = []
    var tmpRange = []
    var tmpRangeHead = null
    var tmpRangeTail = null

    for (let index = 0; index < integer.length; index++) {
        const location = integer[index];
        const parsed = location.parsed
        //start the range
        if (tmpRangeTail == null) {
            tmpRangeHead = parsed;
            tmpRangeTail = parsed;
            tmpRange.push(location)
            continue;
        }

        //if the current location is not 1 more than the last, then the range is over, extra padding because tables are weird i guess
        if (parsed != tmpRangeTail && parsed != tmpRangeTail + 1 && parsed != tmpRangeTail + 2) {
            ranges.push({
                id: ranges.length,
                display: getRangeDisplay(tmpRange),
                start: tmpRangeHead,
                end: tmpRangeTail,
                locations: tmpRange,
            })
            tmpRange = [location]
            tmpRangeHead = parsed
            tmpRangeTail = parsed
        } else {
            tmpRange.push(location)
            tmpRangeTail = parsed
        }
    }
    ranges.push({
        id: ranges.length,
        display: getRangeDisplay(tmpRange),
        locations: tmpRange,
        start: tmpRangeHead,
        end: tmpRangeTail,
    })
    ranges = ranges.concat(nonInteger.map(el => {
        return {
            display: el.locationName,
            locations: [el]
        }
    }))
    var newSection = section
    newSection.locations = ranges
    return newSection
}