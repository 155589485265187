var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("StoreSettingsHeader", {
        attrs: { titleBarActions: _vm.titleBarActions },
      }),
      _c(
        "v-container",
        { staticClass: "fill-height pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-col",
            { attrs: { md: "12" } },
            [
              _c(
                "v-row",
                {
                  staticClass: "action-row mt-0",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.onAdd.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "upsell-logo" }),
                  _c("div", { staticClass: "action-row__title" }, [
                    _vm._v("Add Section"),
                  ]),
                ]
              ),
              _vm.sections.length
                ? _c("v-data-table", {
                    attrs: {
                      items: _vm.sections,
                      headers: _vm.headers,
                      "item-key": "id",
                      "disable-pagination": "",
                      "hide-default-header": "",
                      "hide-default-footer": "",
                      "single-expand": "",
                      expanded: _vm.expanded,
                      "show-expand": "",
                    },
                    on: {
                      "update:expanded": function ($event) {
                        _vm.expanded = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function ({ expand, isExpanded, item }) {
                            return [
                              _c(
                                "tr",
                                {
                                  on: {
                                    click: function ($event) {
                                      return expand(!isExpanded)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "td",
                                    { attrs: { "align-content-center": "" } },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "name-row pt-1 mt-1 fill-height",
                                          attrs: { "align-content": "center" },
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "row__icon",
                                            class: {
                                              "row__icon--active": !isExpanded,
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "row__title" },
                                            [_vm._v(_vm._s(item.name))]
                                          ),
                                          isExpanded && _vm.canEdit
                                            ? _c("div", {
                                                staticClass: "row--edit",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.onEditItem(item)
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          isExpanded && _vm.canEdit
                                            ? _c("div", {
                                                staticClass: "row--delete",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.onDeleteItem(
                                                      item
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  item.online
                                    ? _c("td", { staticClass: "online" }, [
                                        _vm._v("Online"),
                                      ])
                                    : _c("td", [_vm._v("Offline")]),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "no-data",
                          fn: function () {
                            return [_c("div", { attrs: { id: "no-data" } })]
                          },
                          proxy: true,
                        },
                        {
                          key: "expanded-item",
                          fn: function ({ headers, item }) {
                            return [
                              _c("td", { attrs: { colspan: headers.length } }, [
                                _c(
                                  "ul",
                                  { staticClass: "tables-expand-list" },
                                  [
                                    _c("li", [_vm._v("TABLES")]),
                                    _vm._l(item.locations, function (location) {
                                      return [
                                        _c("li", { key: location.display }, [
                                          _vm._v(_vm._s(location.display)),
                                        ]),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3138619285
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }