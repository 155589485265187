<template>
  <div>
    <StoreSettingsHeader :titleBarActions="titleBarActions" />
    <v-container class="fill-height pa-0" fluid>
      <v-col md="12">
        <v-row class="action-row mt-0" @click.stop="onAdd">
          <div class="upsell-logo" />
          <div class="action-row__title">Add Section</div>
        </v-row>
        <v-data-table
          v-if="sections.length"
          :items="sections"
          :headers="headers"
          item-key="id"
          disable-pagination
          hide-default-header
          hide-default-footer
          single-expand
          :expanded.sync="expanded"
          show-expand
        >
          <template v-slot:item="{ expand, isExpanded, item }">
            <tr @click="expand(!isExpanded)">
              <td align-content-center>
                <v-row
                  class="name-row pt-1 mt-1 fill-height"
                  align-content="center"
                >
                  <div
                    class="row__icon"
                    :class="{ 'row__icon--active': !isExpanded }"
                  ></div>
                  <div class="row__title">{{ item.name }}</div>
                  <div
                    v-if="isExpanded && canEdit"
                    class="row--edit"
                    @click.stop="onEditItem(item)"
                  ></div>
                  <div
                    v-if="isExpanded && canEdit"
                    class="row--delete"
                    @click.stop="onDeleteItem(item)"
                  ></div>
                </v-row>
              </td>
              <td v-if="item.online" class="online">Online</td>
              <td v-else>Offline</td>
            </tr>
          </template>

          <template v-slot:no-data>
            <div id="no-data" />
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <ul class="tables-expand-list">
                <li>TABLES</li>
                <template v-for="location in item.locations">
                  <li :key="location.display">{{ location.display }}</li>
                </template>
              </ul>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { createSectionLocationRanges } from "helpers/sections";
import StoreSettingsHeader from "../components/store_settings_header.vue";
import clone from "clone";
import AccessMixin from "mixins/access_helper.js";
import StoreSettingsMixin from "../mixins/store_settings.js";

export default {
    name: "StoreSettingsSections",
    components: {
        StoreSettingsHeader,
    },
    mixins: [AccessMixin, StoreSettingsMixin],

    data() {
        return {
            isMounted: false,
            bottomPadding: 0,
            sections: [],
            headersLength: 2,
            titleBarActions: [],
            rowsPerPageItems: [10, 20, 30, 40, 100],
            expanded: [],
            pagination: {
                rowsPerPage: 100,
                page: 1,
            },
            headers: [
                {
                    sortable: false,
                    text: "",
                    value: "name",
                },
                {
                    sortable: false,
                    text: "",
                    value: "online",
                },
                {
                    sortable: false,
                    text: "",
                    value: "data-table-expand",
                },
            ],
        };
    },
    computed: {
        sectionsForENV() {
            if (this.env == "stage") {
                return this.sectionsStage;
            } else if (this.env == "prod") {
                return this.sectionsProd;
            }
        },
        ...mapGetters(["products", "store"]),
        ...mapGetters("SettingsStore", ["sectionsStage", "sectionsProd"]),
    },
    watch: {
        sectionsForENV() {
            this.sections = clone(this.sectionsForENV).map((el) =>
                createSectionLocationRanges(el)
            );
        },
    },
    created() {
        this.resolvePageTitle();
    },
    mounted() {
        this.isMounted = true;
        let { store } = this.$route.params;
        this.getSections({
            waypointID: store,
            env: this.env,
        });
        if (this.sectionsForENV != undefined) {
            this.sections = clone(this.sectionsForENV).map((el) =>
                createSectionLocationRanges(el)
            );
        }
    },
    methods: {
        resolvePageTitle() {
            if (Object.keys(this.store).length) {
                document.title = `${this.store.storeName} - Table Numbers`;
            }
        },
        onEditItem(section) {
            this.$router.push({
                name: `store-settings-sections-edit`,
                params: { SectionID: section.id },
            });
        },
        onDeleteItem(section) {
            let storeWaypointID = this.$route.params.store;
            return this.deleteSectionByWaypointID({
                storeWaypointID,
                sectionID: section.id,
                env: this.env,
            }).then(() => {});
        },
        onAdd() {
            this.$router.push({ name: `store-settings-section-create` });
        },
        ...mapActions("SettingsStore", [
            "getSections",
            "deleteSectionByWaypointID",
        ]),
    },
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";

#no-data {
  background-color: transparent;
}
.upsell-group-table {
  .v-data-table {
    tbody {
      tr:hover:not(.v-datatable__expand-row) {
        background: white;
      }
      tr {
        td {
          color: #585858;
          vertical-align: middle;
          height: 41px;
          font-size: 14px;
          padding-left: 30px;
          width: 250px;
        }
      }
    }
  }
}
.row {
  &__title {
    padding-left: spacing(xs);
  }

  &__icon {
    @include background-image("", "section_normal.svg", "../img/");
    background-size: contain;
    width: spacing(sm);
    height: spacing(sm);
  }

  &--delete {
    @include background-image("", "delete.svg", "../img/");
    background-size: 20px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-top: -2px;
  }
  &--edit {
    @include background-image("", "edit_logo.svg", "../img/");
    background-size: 14px;
    cursor: pointer;

    margin-left: 15px;
    width: 20px;
    height: 20px;
    padding: 5px;
  }
}

.action-row {
  padding: 15px;
  cursor: pointer;
  align-items: center;
  &__title {
    margin-left: 10px;
    color: $brand_color !important;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
  }
}
.upsell-logo {
  @include background-image("", "section_add.svg", "../img/");
  flex-grow: 0 !important;
  width: spacing(sm);
  height: spacing(sm);
  background-size: contain;
}

.tables-expand-list {
  background-color: #f9f9f9;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}

.tables-expand-list li {
  padding-bottom: 1px;
}

.online {
  color: $brand_color !important;
}
</style>
